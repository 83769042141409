import React from 'react'
import { useEffect } from 'react'
import logo from '../icons/logo.png'
import missionimg from '../cardImages/mission.png'
import missionicon from '../icons/missionicon.png'
import vissionimg from '../cardImages/vission.png'
import vissionicon from '../icons/vissionicon.png'
import wwdis from '../cardImages/wwdisc.jpeg'
import wwcard1 from '../cardImages/Operational Optimization - Who We Are Page.jpg'
import wwcard2 from '../cardImages/Regulatory Compliance - Who We Are Page.jpg'
import wwcard3 from '../cardImages/Revenue Cycle Management (RCM) - Who We Are Page.jpg'
import wwcard4 from '../cardImages/Practice Solutions - Who We Are Page.jpg'

import './About.css'
import { Link } from 'react-router-dom'

function AboutUs() {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + '/Avanza updated broucher.pdf'; 
    link.download = 'Avanza BPO LLC Trifold Brochure (Trifolds).pdf'; 
    link.click();
  };

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.defer = true;
  //   script.src = "https://www.chatbase.co/embed.min.js";
  //   script.setAttribute('chatbotId', 'rEsIt58ChZ77iEZ_yRNy_');
  //   script.setAttribute('domain', 'www.chatbase.co');

  //   // Append the script to the body
  //   document.body.appendChild(script);

  //   // Clean up the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
const counters = document.querySelectorAll('.count');
const speed = 100; // Adjust the speed for faster or slower counting

counters.forEach(counter => {
  const updateCount = () => {
    const target = +counter.getAttribute('data-target');
    const count = +counter.innerText;

    // Calculate the increment
    const increment = target / speed;

    // If count is still less than target, increment the count
    if (count < target) {
      counter.innerText = Math.ceil(count + increment);
      setTimeout(updateCount, 20);
    } else {
      counter.innerText = target;
    }
  };

  updateCount();
});

useEffect(() => {
  const element = document.getElementById(window.location.hash.substring(1));
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}, []);

  return (
    <div>
      <div  className='aboutus_banner'>
        <img src={logo} alt="" />
      </div>
      {/* --------------------------------------Side name bar for discription------------------------------------------------------------- */}
      <div className='Whowe-body' style={{width:'100%',display:'flex',justifyContent:'space-around'}}>
        <div className='Whowe-nav'>
          <a href="#whoweare"><button className="dbsection">Who We Are</button></a>
          <a href="#mission"><button className="dbsection"> Mission</button></a>
          <a href="#vission"><button className="dbsection">Vission</button></a>
          <a href="#KeyAreas"><button className="dbsection">Consulting Package</button></a>
          <a href="#KeyAreas"><button className="dbsection">Personalized Training</button></a>
          <a href="#KeyAreas"><button className="dbsection">Medical Coding and Billing</button></a>
          <a href="#Account Receivable"><button className="dbsection">Remittance posting</button></a>
          <a href="#Account Receivable"><button className="dbsection">Account Receivable (AR) Follow-Up</button></a>
          <a href="#Achieve Measurable Results"><button className="dbsection">Achieve Measurable Results</button></a>
          <a href="#Advanced Analytics"><button className="dbsection">Advanced Analytics</button></a>
        </div>
        <div className='Whowe-main'>
        <div className='wwexp'>
          <div className='wwexp1'>
            <h1>20 + </h1>
            <p>Year of experience </p>
          </div>
          <div className='wwexp1'>
          <h1>10 + </h1>
          <p>Specialities served </p>
          </div>
          <div className='wwexp1'>
          <h1>99 %</h1>
          <p>Customer retention </p>
          </div>
        </div>
        <hr />
          <h1 id='AboutUs'  data-aos="zoom-in" >Welcome To Avanza BPO LLC ! </h1>
          {/* ------------------------------------- Who we are discription --------------------------------------------------------------------------- */}
          <div data-aos="zoom-out" className='whowe-hero'>
            <div className='herosec'>
              <div className="herosec1">
                <img src={logo} alt="" />
              </div>
              <div className="herosec2">
                <h1 style={{color:'#083b4a'}}>Who We Are </h1>
                <p ><span >Avanza BPO LLC</span> is a premier practice solutions firm recognized for its profound expertise in the medical field. With over 20 years of dedicated experience, we excel in providing top-tier services that encompass both front-end and back-end practice operations.</p>
              </div>
            </div>
          </div>
        {/* -------------------------------------details discription about avanza ---------------------------------------------------------------- */}
        <div className='avan-dis' >
          <hr  />
          <p >Healthcare Elevation</p>
        </div>
        <div className="wwdiscription">
          <div data-aos="fade-right" className="wwdiscriptionsec1">
            <h1><span style={{color:'#367588',fontWeight:'600'}}>Empowering </span>Healthcare Through Operational <span style={{color:'#367588',fontWeight:'600'}}>Excellence</span></h1>
            <p>Avanza BPO LLC specializes in customized revenue cycle management (RCM) services designed to optimize healthcare providers' financial operations. Our offerings include claims processing, denial management, accounts receivable follow-up, and payer services, all personalized to address the specific needs of specialty-focused practices. We provide accurate medical coding and billing solutions, seamless software integration, and consulting services, including personalized training for existing RCM teams to enhance efficiency and streamline workflows. Our expert team analyzes workflows to identify areas for improvement through automation and optimization, helping reduce errors and boost productivity. Committed to supporting healthcare organizations, our mission is to drive financial resilience and sustainable growth. Refer to 'What we offer' for the service that meets your needs.</p>
          </div>
          <div data-aos="fade-left" className="wwdiscriptionsec2">
            <img  src={wwdis} alt="" />
          </div>
        </div>
        {/* --------------------------------------Mission and Vission part---------------------------------------------------------------   */}
        <div >
        <div className='mission-and-vission' >
          <div id='mission' >
            <img data-aos="fade-in" className='mission-img'  src={missionimg} alt="" />
          </div>
          <div className='mission-cont'>
          <h1 data-aos="fade-in" >Mission <span> <img className='mission-icon' src={missionicon} alt="" /></span></h1>
          <p data-aos="fade-in" >To help healthcare organizations achieve financial stability and sustainable growth through efficient, reliable, and customized revenue cycle management solutions."</p>
          </div>
        </div>
        <hr style={{margin:'2% 0% 2% 0%', }} />      
        <div id='vission' >
          <div className='vission-cont'>
          <h1 data-aos="fade-in" className='vission' >Vision <span> <img className='vission-icon' src={vissionicon} alt="" /></span></h1>
          <p data-aos="fade-in" style={{paddingRight:'5%'}}>To be the leading partner for healthcare providers, driving operational excellence and sustainable business growth through innovative and customized solutions.</p>
          </div>
          <div style={{width:'100%'}}>
            <img data-aos="fade-in" className='vission-img'  src={vissionimg} alt="" />
          </div>
        </div>
        </div>
        {/* ------------------------------------------- 4 firm is distinguished by its...--------------------------------------------------------------------------------------------------------------------------- */}
        <h1 id='KeyAreas' data-aos="zoom-in" className='four-firm-heading' >Our firm is distinguished by its comprehensive understanding and proficiency in several key areas</h1>
        <div  data-aos="fade-left" data-aos-offset="300" data-aos-duration="300" id='MedicalBillingandCoding' className="portion4">
        
        <div id='CollectionsandReimbursement' className="por4">
            {/* <MdDocumentScanner size={100} style={{ fill: '#CE762E'}} /> */}
            <a id='Account Receivable' href="/whatweoffer/ConsultingPackage"><h1>Consulting Package</h1></a> 
          </div>

          <div id='StaffTraining' className="por4">
            {/* <FaChalkboardTeacher size={50} style={{ fill: '#CE762E',marginTop:'10px'}} /> */}
            <a href="/whatweoffer/PersonalizedTrainning"><h1 >Personalized Training for Operations Staff</h1></a> 
          </div>

          <div id='MedicalAuditing' className="por4">
            {/* < AiOutlineAudit size={50} style={{ fill: '#CE762E',marginTop:'10px'}} /> */}
            <a href="/whatweoffer/MedicalCoding"> <h1 >Medical Coding</h1></a>
          </div>

          <div  className="por4">
            <a href="/whatweoffer/MedicalBilling"><h1>Medical Billing</h1></a> 
          </div>
          
          <div id='StaffTraining' className="por4">
            {/* <FaChalkboardTeacher size={50} style={{ fill: '#CE762E',marginTop:'10px'}} /> */}
            <a href="/whatweoffer/Remittance Posting"><h1 >Remittance posting</h1></a> 
          </div>

          <div id='StaffTraining' className="por4">
            {/* <FaChalkboardTeacher size={50} style={{ fill: '#CE762E',marginTop:'10px'}} /> */}
            <a href="/whatweoffer/Accounts Receivable (AR) Follow-Up"><h1 >Account Receivable (AR) Follow-Up</h1></a> 
          </div>
        </div>
        <div data-aos="fade-up" className='percentage-portion'>
          <h1 className='pp-heading'>Achieve Measurable, Proven Results</h1>
          <div className='pp-sec'>
            <div className="pp1">
              <h3>Cost Reduced</h3>
              <p>upto</p>
              <h1 id="count" data-target="1500">35<span>%</span></h1>
              <p>Reduced operational cost</p>
            </div>
            <div className="pp1">
            <h3>RevCycle Edge</h3>
              <p>upto</p>
              <h1 id="count" data-target="1500">97<span>%</span></h1>
              <p>Improve Revenue Cycle Efficiency</p>
            </div>
            <div className="pp1">
            <h3>Improved Collections</h3>
              <p>upto</p>
              <h1 id="count" data-target="1500">90<span>%</span></h1>
              <p>Improved Collections of process</p>
            </div>
            <div className="pp1">
            <h3>Reduce Admin Errors</h3>
              <p>upto</p>
              <h1 id="count" data-target="1500">98<span>%</span></h1>
              <p>Reduction in Administrative Errors</p>
            </div>
            <div className="pp1">
            <h3>Improved Productivity</h3>
              <p>upto</p>
              <h1 id="count" data-target="1500">99<span>%</span></h1>
              <p>Improved Productivity of work</p>
            </div>
            <div className="pp1">
            <h3>First Pass Resolution Rate</h3>
              <p>upto</p>
              <h1 id="count" data-target="1500">98<span>%</span></h1>
              <p id='Achieve Measurable Results'>Reduction in Denial Rates</p>
            </div>
          </div>
        </div>
{/* -----------------------------------Enhancing Healthcare Efficiency with Advanced Analytics -------------------------------------------------------------------------------- */}
     
      <div id='Analytics' style={{marginTop:'5%'}} className='portion5'>
      <hr />
        <h1 id='Advanced Analytics'  className='portion5-heading' >Enhancing Healthcare Efficiency <br />with Advanced Analytics</h1>
        <p className='portion5-subheading' >Avanza BPO LLC is dedicated to improving the overall health and efficiency of medical practices through advanced analytical services. Our approach is characterized by:</p>
        <div className='por5sec'>
          <div  data-aos="fade-right" id='OperationalOptimization' className="por5sec1">
            <div className='por5sec1img'>
              <img src={wwcard1} alt="" />
            </div>
            <div className='por5sec1cont'>
              <h3>Operational Optimization:</h3>
              <p>Streamlining processes to boost productivity and reduce operational costs, creating a more efficient workflow.</p>
            </div>
          </div>
          <div  data-aos="fade-left" className="por5sec2">
          <div id='RegulatoryCompliance' className='por5sec2img'>
              <img src={wwcard2} alt="" />
            </div>
            <div className='por5sec2cont'>
              <h3>Regulatory Compliance:</h3>
              <p>Ensuring rigorous adherence to industry regulations and standards to mitigate risks and avoid potential compliance issues.</p>
            </div>
          </div>
          <div  data-aos="fade-right" className="por5sec3">
          <div id='RCM' className='por5sec3img'>
              <img src={wwcard3} alt="" />
            </div>
            <div className='por5sec3cont'>
              <h3>Revenue Cycle Management (RCM):</h3>
              <p>Enhancing financial outcomes by effectively managing the revenue cycle from start to finish, improving cash flow and financial stability.</p>
            </div>
          </div>
          <div data-aos="fade-left" className="por5sec4">
          <div id='PracticeSolutions' className='por5sec4img'>
              <img src={wwcard4} alt="" />
            </div>
            <div className='por5sec4cont'>
              <h3>Practice Solutions:</h3>
              <p>Providing comprehensive solutions that address the unique challenges of medical practices, customized to drive operational excellence and sustained growth.</p>
            </div>
          </div>
        </div>
      </div>
      <div id='DownloadBrochure' className='who-we-postion6' style={{margin:'5% 2%',padding:'3%',backgroundColor:'#c3c3c3ae'}}>
        <h1 >Transforming Healthcare for Lasting Success</h1>
        <p style={{marginTop:'2%'}}>Our commitment is to empower healthcare organizations with customized solutions that drive excellence in practice management and support their long-term success. Through our expert services and comprehensive staff training, we enable practices to achieve their operational goals and thrive in a competitive landscape.</p>
      </div>      
      </div> {/*----------------- end of main div ------------------------------*/}
      </div>
      {/* <Chatbot /> */}
      <div id='download' className='downloadcontainer'>
        <h1 style={{textAlign:'center', color:'white'}}>Unlock the Future of Healthcare Efficiency</h1>
        <p style={{textAlign:'center', color:'white',margin:'0 10%'}}>Download our comprehensive brochure to discover how Avanza BPO can transform your healthcare practice with customized solutions. Get the insights and expertise you need to optimize your operations today!</p>
        <div style={{display:'flex', justifyContent:'center',marginTop:'2%'}}>
        <button onClick={handleDownload} className='downbtn'>download</button>
        </div>
      </div>

    </div>
   
  )
}

export default AboutUs