import React from 'react'

function DemoandCharges() {
  return (
    <div className='carousal-session2-1'>
        <h1>Demo and Charge Entry</h1>
        <p>Are essential steps in the healthcare revenue cycle that involve capturing patient demographic information and entering charges for medical services. These processes ensure accurate billing and smooth claim submission .</p>
    </div>
  )
}

export default DemoandCharges