import React from 'react'
import ContForm from '../../contactform-component/ContForm'

function AccRec() {
  return (
    <div>
    <div className='whatwedo-banner'>
        <h1>Accounts Receivable (AR) Follow-Up</h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p style={{marginTop:'5%',textAlign:'justify'}}>Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>
    
    <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'5%'}}>
      <div className='offer-content' style={{display:'flex'}}>
        <div className='consulting-left-session'>
          <a href="#Sort Payers by Timely Filing"><button className="consulting-left-session-btn-1">Sort Payers by Timely Filing and Appeal Limits</button></a>
          <a href="#Prioritize Payers with Shorter Timely Filing/Appeal Limits"><button className="consulting-left-session-btn-2">Prioritize Payers with Shorter Timely Filing/Appeal Limits</button></a>
          <a href="#Categorize Collectable and Uncollectable Claims"><button className="consulting-left-session-btn-3">Categorize Collectable and Uncollectable Claims</button></a>
          <a href="#Focus on Collectable Claims"><button className="consulting-left-session-btn-4">Focus on Collectable Claims</button></a>
          <a href="#Identify and Resolve Global Trends in Bulk"><button className="consulting-left-session-btn-4">Identify and Resolve Global Trends in Bulk</button></a>
          <a href="#Sort Claims by High-Dollar Value"><button className="consulting-left-session-btn-4">Sort Claims by High-Dollar Value</button></a>
          <a href="#Prioritize Claims Nearing Timely Filing Limits"><button className="consulting-left-session-btn-4">Prioritize Claims Nearing Timely Filing Limits (60+ Days)</button></a>
          <a href="#Aging Report Organization – Oldest to Newest"><button className="consulting-left-session-btn-4">Aging Report Organization – Oldest to Newest</button></a>
  
        </div>
        <div className='consulting-right-session'>
        <h1 className='consulting-right-session-title' id='Sort Payers by Timely Filing' style={{textAlign:'center', margin:'2% 10% 0 10%'}}>Accounts Receivable (AR) Follow-Up </h1>
        <p className='consulting-right-session-content-para' style={{textAlign:'justify',padding:'2% 5% 2% 5%'}}>Avanza BPO LLC offers a specialized Account Receivable (AR) collection services designed to optimize cash flow and improve collection rates for healthcare providers. Our approach focuses on prioritizing high-value claims, expediting collections on accounts nearing timely filing limits, and targeting collectable claims to maximize revenue. Through efficient payer management, trend analysis for bulk resolution, and systematic aging reports, we ensure that overdue and at-risk accounts receive prompt attention, leading to reduced AR days and increased financial stability for our clients.</p>

        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Sort Payers by Timely Filing and Appeal Limits</p>
          <p id="Prioritize Payers with Shorter Timely Filing/Appeal Limits" className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	We categorize payers based on their specific timely filing (TFL) and appeal limits to ensure compliance and avoid unnecessary denials. This allows us to manage each payer's requirements efficiently.</p>
        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Prioritize Payers with Shorter Timely Filing/Appeal Limits</p>
          <p id='Categorize Collectable and Uncollectable Claims' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Claims with tighter TFL/appeal limits are prioritized, ensuring that we meet the deadlines set by these payers and reduce the risk of missing filing windows, which can lead to lost revenue.</p>
        </div>
        <hr  />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Categorize Collectable and Uncollectable Claims</p>
          <p id='Focus on Collectable Claims' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Claims are sorted into "collectable" and "uncollectable" categories to focus resources effectively. By identifying uncollectable claims early, we prevent time and resources from being wasted on low-return tasks.</p>
        </div>
        <hr  />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Focus on Collectable Claims</p>
          <p id='Identify and Resolve Global Trends in Bulk'className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Our team directs attention exclusively to collectable claims to maximize revenue potential. This targeted approach increases collection efficiency and improves overall cash flow.</p>

        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Identify and Resolve Global Trends in Bulk</p>
          <p id='Sort Claims by High-Dollar Value' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	We identify common trends in denials or claim issues that can be resolved on a large scale, allowing for bulk resolutions and reducing the time spent on individual claim corrections.</p>
        </div>
        <hr />
        {/* <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Updating Patient Records</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Maintaining accurate and up-to-date records of all communications with insurance companies and patients.</p>
          <p id='Sort Claims by High-Dollar Value' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Reflecting all changes and updates in the billing system.</p>
          <hr />
        </div> */}
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Sort Claims by High-Dollar Value</p>
          <p id='Prioritize Claims Nearing Timely Filing Limits' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>• Claims are sorted by dollar amount, allowing our team to prioritize high-value claims. This ensures that substantial revenue opportunities are addressed promptly.</p>
          <hr />
        </div>
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Prioritize Claims Nearing Timely Filing Limits (60+ Days)</p>
          <p id='Aging Report Organization – Oldest to Newest' className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Claims close to their TFL deadlines, especially those older than 60 days, receive immediate attention. This proactive approach minimizes the risk of missing deadlines, thus enhancing revenue recovery.</p>

        </div>
        <hr />
        <div style={{padding:'0 0% 0 5%',marginTop:'5%'}}>
          <p className='consulting-right-session-content-title' style={{color:'#cf7531',fontWeight:'600'}}>- Aging Report Organization – Oldest to Newest</p>
          <p className='consulting-right-session-content-para' style={{margin:'3% 0 0 3%'}}>•	Claims are tracked in an aging report structured from oldest to newest, categorized as 0-30, 31-60, 61-90, and over 120 days. This breakdown enables systematic follow-up, ensuring older claims are addressed first to maximize timely collections.</p>

        </div>
        </div>

      </div>
    </div>
    <hr style={{marginTop:'3%'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{marginLeft:'2%',fontSize:'30px',fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>
    </div>
  )
}

export default AccRec