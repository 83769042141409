import './App.css';
import Nav from './Components/NavComponent/Nav';
import Footer from './Components/footer compoenent/Footer';
import {BrowserRouter} from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useState } from 'react';
import Main from './Main';

function App() {

  useEffect(()=>{
    Aos.init({duration: 800,easing: 'ease-in-out', })        //-> This is used for the container animation effect while scrolling

  },[])

  return (
    <div className="App">
      <BrowserRouter>
      <Nav />
      <Main />
      <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
