import React from 'react'

function Claimsub() {
  return (
    <div className='carousal-session2-1'>
        <h1>Claim submission</h1>
        <p>It is a critical step in the healthcare revenue cycle, involving the process of sending medical claims to insurance companies for reimbursement. Proper and timely claim submission ensures that healthcare providers are paid for the services rendered to patients.</p>
    </div>
  )
}

export default Claimsub