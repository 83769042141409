import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './Components/Home component/Home';
import AboutUs from './Components/AboutUs component/AboutUs';
import WhatWeDo from './Components/Whatwedo component/WhatWeDo';
import Careers from './Components/Careers component/Careers';
import ContactUs from './Components/Contactus component/ContactUs';
import Specialities from './Components/Specialities compoent/Specialities';
import Loader from './loader component/Loader'; // Import loader
import ConsultingPackage from './Components/Whatwedo component/ConsultingPackage';
import PersonalizedTrainning from './Components/Whatwedo component/PersonalizedTrainning';
import DanielManage from './Components/Whatwedo component/DanielManage';
import AffiliatedServices from './Components/Whatwedo component/AffiliatedServices';
import RCM from './Components/Whatwedo component/RCM component/RCM';
import ACM from './Components/Whatwedo component/RCM component/ACM';
import Analytics from './Components/Whatwedo component/RCM component/Analytics';
import DemoandCharges from './Components/Whatwedo component/RCM component/DemoandCharges';
import PatientBilling from './Components/Whatwedo component/RCM component/PatientBilling';
import PayPost from './Components/Whatwedo component/RCM component/PayPost';
import PreAuth from './Components/Whatwedo component/RCM component/PreAuth';
import ProviEnrol from './Components/Whatwedo component/RCM component/ProviEnrol';
import VerifyBen from './Components/Whatwedo component/RCM component/VerifyBen';
import MedicalCodingPart from './Components/Whatwedo component/MedicalCodingPart';
import AccRec from './Components/Whatwedo component/AccRec';
import Remittance from './Components/Whatwedo component/Remittance';
import MedicalCoding from './Components/Whatwedo component/RCM component/MedicalCoding';
import ScrollTop from './ScrollTop';
import ClaimRejection from './Components/Whatwedo component/RCM component/ClaimRejection';
import Claimsub from './Components/Whatwedo component/RCM component/Claimsub';
import MedicalBillingPart from './Components/Whatwedo component/MedicalBillingPart';
const Main = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Now it can access Router context

  useEffect(() => {
    // Show loader on route change
    const handleStart = () => {
      setLoading(true);
    };

    // Hide loader after a short delay or when page is loaded
    const handleEnd = () => {
      setTimeout(() => setLoading(false), 1000); // Adjust delay as needed
    };

    handleStart();
    handleEnd();
  }, [location]);

  return (
    <>
      {loading && <Loader />} {/* Show loader when loading */}
      <ScrollTop /> {/* Add ScrollToTop here */}
      <div className='main' style={{ paddingTop: '80px' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/whoweare" element={<AboutUs />} />
          <Route path="/whatweoffer" element={<WhatWeDo />} />
          <Route path="/whatweoffer/ConsultingPackage" element={<ConsultingPackage />} />
          <Route path="/whatweoffer/PersonalizedTrainning" element={<PersonalizedTrainning />} />
          <Route path="/whatweoffer/DanielManage" element={<DanielManage />} />
          <Route path="/whatweoffer/MedicalCoding" element={<MedicalCodingPart />} />
          <Route path="/whatweoffer/MedicalBilling" element={<MedicalBillingPart />} />
          <Route path="/whatweoffer/Accounts Receivable (AR) Follow-Up" element={<AccRec />} />
          <Route path="/whatweoffer/Remittance Posting" element={<Remittance />} />


          <Route path="/whatweoffer/Revenue-Cycle-Management" element={<RCM />} >
          <Route path="/whatweoffer/Revenue-Cycle-Management/" element={<VerifyBen />} />
          <Route path="/whatweoffer/Revenue-Cycle-Management/Medical-Coding" element={<MedicalCoding />} />
          <Route path="/whatweoffer/Revenue-Cycle-Management/Demo-and-Charge-Entry" element={<DemoandCharges />} />
          <Route path="/whatweoffer/Revenue-Cycle-Management/Claims-Submission" element={<Claimsub />} />
          <Route path="/whatweoffer/Revenue-Cycle-Management/Claim-Rejection-and-Correction" element={<ClaimRejection />} />
          <Route path="/whatweoffer/Revenue-Cycle-Management/Payment-Posting" element={<PayPost />} />
          <Route path="/whatweoffer/Revenue-Cycle-Management/Report-and-Analytcs" element={<Analytics />} />
          <Route path="/whatweoffer/Revenue-Cycle-Management/Account-Receivable-Management" element={<ACM />} />          
          <Route path="/whatweoffer/Revenue-Cycle-Management/PatientStatement" element={<PatientBilling />} />
          </Route>
          <Route path="/whatweoffer/Affiliated-Services" element={<AffiliatedServices />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/specialities" element={<Specialities />} />
        </Routes>
      </div>
    </>
  );
};

export default Main;
