import React, { useState } from 'react'
import './Nav.css'
import { Turn as Hamburger } from 'hamburger-react'

import logo from './logo.png'
import { MdMargin } from 'react-icons/md';
function Nav() {
//   window.addEventListener('scroll', function() {
//     const navbar = document.getElementById('li');
//     if (window.scrollY > 0) { // Adjust this value to when you want the color to change
//         navbar.classList.add('scrolled');
//     } else {
//         navbar.classList.remove('scrolled');
//     }
// });

  // State to handle menu toggle
  const [isActive, setIsActive] = useState(false);

  // Function to toggle the menu
  const handleToggle = () => {
    setIsActive(!isActive); // Toggle active state
  };

  const[issubActive, setIsSubActive] = useState(false)

  const handleSubMenu = () =>{
    setIsSubActive(!issubActive);
  }
  const [isOpen, setOpen] = useState(false)
  const [showWhoWeAre, setShowWhoWeAre] = useState(false);
  const [showWhatWeOffer, setShowWhatWeOffer] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const toggleWhoWeAre = () => setShowWhoWeAre(!showWhoWeAre);
  const toggleWhatWeOffer = () => setShowWhatWeOffer(!showWhatWeOffer);

  return (
    <header className="nav">
    <nav className="navbar">
      <img className="logo" src={logo} alt="Logo" />
      {/* Hamburger toggle button */}
      <div className={`nav-toggle ${isActive ? 'active' : ''}`} onClick={handleToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      {/* Navigation options */}
      <div className={`options ${isActive ? 'active' : ''}`}>
        <div className="option1">
        <a className='li' href="/">Home</a>
        </div>
        <div className="option2">
        <a className='li' href="/whoweare">Who We Are</a>
        <div className='sub-option' style={{height:'280px'}}>
          <a style={{borderBottom:'1px solid gray'}} className='sub-option-li' href="/whoweare#AboutUs" > <li>About Us</li></a>
          <a style={{borderBottom:'1px solid gray'}} className='sub-option-li' href="/whoweare#mission"><li>Mission</li></a>
          <a style={{borderBottom:'1px solid gray'}} className='sub-option-li' href="/whoweare#vission"><li>Vision</li></a>
          <a style={{borderBottom:'1px solid gray'}} className='sub-option-li' href="/whoweare#KeyAreas"><li>Key Areas</li></a>
          <a style={{borderBottom:'1px solid gray'}} className='sub-option-li' href="/whoweare#Analytics"><li>Analytics</li></a>
          <a style={{borderBottom:'1px solid gray'}} className='sub-option-li' href="/whoweare#download"><li>Brochure</li></a>
        </div>
        </div>
        <div className="option3">
        <a className='li' href="/whatweoffer">What We Offer</a>
        <div className='sub-option' style={{height:'300px',padding:'0 5% 0 0'}} >
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/ConsultingPackage" > <li>Consulting Package</li></a>
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/PersonalizedTrainning"><li>Personalized Training</li></a>
          {/* <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/DanielManage"><li>Denial Management Handbook</li></a> */}
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/MedicalCoding"><li>Medical Coding</li></a>
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/MedicalBilling"><li>Medical Billing</li></a>
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/Remittance Posting"><li>Remittance posting</li></a>
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/Accounts Receivable (AR) Follow-Up"><li>Accounts Receivable (AR) Follow-Up</li></a>
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/DanielManage"><li>Denial Management</li></a>
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/Affiliated-Services"><li>Affiliated Services</li></a>
          <a style={{borderBottom:'1px solid rgba(177, 177, 177, 0.732)',borderRadius:'5px',width:'200px',boxShadow:'5px 5px 10px #babecc, -5px -5px 10px #ffffff'}} className='sub-option-li' href="/whatweoffer/Revenue-Cycle-Management"><li><abbr title="Revenue Cycle Management"> RCM - Solutions </abbr></li></a>
        </div>
        </div>
        <div className="option4">
        <a className='li' href="/specialities">Specialities</a>
        </div>
        <div className="option6">
        <a className='li' href="/contactus">Contact Us</a>
        {/* <div className='sub-option' style={{height:'100px'}}>
          <a style={{borderBottom:'1px solid gray'}} className='sub-option-li' href="/contactus#formposition" > <li>Carrers</li></a>
        </div> */}
        </div>
      </div>
    </nav>




    <nav className='mobile-nav'>
      <div  style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
      <div>
      <img className='mobile-nav-logo'src={logo} alt="Logo" />
      </div>
      
      <div className={`nav-toggle ${isActive ? 'active' : ''}`} onClick={handleToggle}>
      <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
        <div className={`options ${isActive ? 'active' : ''}`}>
          <a href="/"><li>Home</li></a>
          <li onClick={toggleWhoWeAre} className="dropdown">
          Who We Are
          {showWhoWeAre && (
            <ul className="dropdown-menu">
              <a className='mobile-sub-options' href="/whoweare"><button>Who We Are</button></a>
              <a className='mobile-sub-options' href="/whoweare#AboutUs"><button>About Us</button></a>
              <a className='mobile-sub-options' href="whoweare#mission"><button>Mission</button></a>
              <a className='mobile-sub-options' href="/whoweare#vission"><button>Vission</button></a>
              <a className='mobile-sub-options' href="/whoweare#KeyAreas"><button>Key Areas</button></a>
              <a className='mobile-sub-options' href="/whoweare#Analytics"><button>Analytics</button></a>
              <a className='mobile-sub-options' href="/whoweare#download"><button>Brochure</button></a>

            </ul>
          )}
        </li>
          <li onClick={toggleWhatWeOffer} className="dropdown">
          What We Offer
          {showWhatWeOffer && (
            <ul className="dropdown-menu">
              <a className='mobile-sub-options' href="/whatweoffer"><button>What We Offer</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/ConsultingPackage"><button>Consulting Package</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/PersonalizedTrainning"><button>Personalized Training</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/MedicalCoding"><button>Medical Coding</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/MedicalBilling"><button>Medical Billing</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/Remittance Posting"><button>Remittance posting</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/Accounts Receivable (AR) Follow-Up"><button>Accounts Receivable (AR) Follow-Up</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/DanielManage"><button>Denial Management</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/Affiliated-Services"><button>Affiliated Services</button></a>
              <a className='mobile-sub-options' href="/whatweoffer/Revenue-Cycle-Management"><button>RCM - Solutions</button></a>
            </ul>
          )}
        </li>
        <a href="/specialities"><li>Specialities</li></a>
        <a href="/contactus"><li>Contact Us</li></a>
      </div>
      </div>
    </nav>
  </header>

  )
}

export default Nav
