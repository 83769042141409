import React from 'react'

function AffiliatedServices() {
  return (
    <div>
      This Page in Construction
    </div>
  )
}

export default AffiliatedServices