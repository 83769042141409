import React from 'react'

function VerifyBen() {
  return (
    <div className='carousal-session2-1'>
        <h1>Verification of Benefits</h1>
        <p>Helps streamline the billing process, prevents claim rejections, and improves patient satisfaction by providing a clear understanding of coverage and financial obligations before care is provided.</p>
    </div>
  )
}

export default VerifyBen