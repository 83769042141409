import React from 'react'
import './Whatwedo.css'
import consultingicon from './wwd-icons/consulting-package.png'
import trainer from './wwd-icons/Trainer.png'
import RCMicon from './wwd-icons/RCM-cycle icon.png'
import medicalCoding from './wwd-icons/medical-coding.png'
import medicalBilling from './wwd-icons/medical-billing.png'
import danielManagement from './wwd-icons/Daniel management.png'
import remittance from './wwd-icons/remittance.png'
import accRecievable from './wwd-icons/Account-recievable.png'
import { Link } from 'react-router-dom';
import ContForm from '../../contactform-component/ContForm';

import { useEffect } from 'react';

function WhatWeDo() {

  useEffect(() => {
    const element = document.getElementById(window.location.hash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div>
      <div  className='whatwedo-banner'>
        <h1>What We Offer <br /><span>Transform Your Practice, Maximize Efficiency, and Drive Success</span></h1>
      </div>
      {/* ----------------------------------------- section 1 discription and short explanation ---------------------------------------------------- */}
    <div className='whatwedo-dis'>
      <div className='whatwedo-dis-sec1-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{fontWeight:'600',color:'#cf7531'}}>What We Offer</h3>
        </div>
        <h2  style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Your Path to Peak Performance Starts Here – Explore the Possibilities</h2>
      </div>
      <div className='whatwedo-dis-sec1-2'>
        <p >Welcome to Avanza BPO LLC, where our mission is to empower healthcare practices with comprehensive solutions that drive efficiency, excellence., and enhance the overall health of healthcare providers’ business. Led by our founder, Leidy Arguelles, our consulting package is designed to transform and elevate your healthcare business.</p>
      </div>
    </div>


    <div id="whatweoffer" className='wwd-sec2'>
      <div className="wwd-sec2-1">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={consultingicon} alt="" />
          <h3 >Consulting Package</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/ConsultingPackage" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont'  >Avanza BPO offers a consulting package designed to optimize your business operations through detailed monthly reports, customized training sessions, valuable resources, and ongoing consultation as needed.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>
      </div>


     <div className="wwd-sec2-2">
        <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={trainer} alt="" />
          <h3 >Personalized Training for Operations Staff</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/PersonalizedTrainning" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our Personalized Training for Operations Staff is specifically designed to enhance team efficiency by addressing skill gaps and practice-specific needs. This customized program ensures that your operations team is equipped with the tools and knowledge necessary to improve productivity and performance.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>
      </div>


      <div className="wwd-sec2-3">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={medicalCoding} alt="" />
          <h3 >Medical Coding</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/MedicalCoding" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Avanza BPO LLC delivers a comprehensive medical coding service crafted to achieve the highest standards in accuracy, compliance, and operational efficiency. Our team of expert coders meticulously handles every aspect of coding, including ICD-10 for accurate diagnoses, CPT for procedures and services, and HCPCS for non-physician services and durable medical equipment. This process ensures precise and timely claim preparation, which streamlines revenue cycle management for improved practice profitability.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>
      </div>
      
      <div className="wwd-sec2-5">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={medicalBilling} alt="" />
          <h3 >Medical Billing</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/MedicalBilling" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Avanza BPO LLC provides a robust medical billing service designed to ensure complete accuracy, regulatory compliance, and maximum efficiency for your practice. With a focus on capturing all billable services and achieving a high first-pass resolution rate, we streamline the entire billing process to reduce delays and enhance profitability. Our expertise includes managing primary claims, handling complex secondary billing, special invoicing for Workers’ Compensation (W/C) and travelers' insurance, and addressing contract-specific requirements, all to optimize revenue cycle management and cash flow for your practice.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div>
      <div className="wwd-sec2-4">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={remittance} alt="" />
          <h3 >Remittance posting</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/Remittance Posting" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >At Avanza BPO LLC, we offer remittance posting as a crucial component of revenue cycle management, ensuring that payments are accurately recorded, reconciled, and comply with payer contracts. Our comprehensive, end-to-end remittance posting services are designed to streamline this process for healthcare providers.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div>
      <div className="wwd-sec2-6">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={accRecievable} alt="" />
          <h3 >Accounts Receivable (AR) Follow-Up</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/Accounts Receivable (AR) Follow-Up" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Avanza BPO LLC offers a specialized Account Receivable (AR) collection services designed to optimize cash flow and improve collection rates for healthcare providers. Our approach focuses on prioritizing high-value claims, expediting collections on accounts nearing timely filing limits, and targeting collectable claims to maximize revenue. Through efficient payer management, trend analysis for bulk resolution, and systematic aging reports, we ensure that overdue and at-risk accounts receive prompt attention, leading to reduced AR days and increased financial stability for our clients.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div>
      <div className="wwd-sec2-7">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={danielManagement} alt="" />
          <h3 >Denial Management Services</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/DanielManage" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >Our Denial Management Services are designed to effectively address and resolve denied insurance claims, reducing the occurrence of future denials and improving the overall revenue cycle process. The service is comprehensive, covering denial analysis, resolution, appeals, communication with payers, and prevention strategies, ensuring compliance with all relevant regulations.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div>
      <div className="wwd-sec2-7">
      <div style={{display:'flex',justifyContent:'space-evenly', padding:'5%'}}>
          <img className='wwd-card-icon' src={RCMicon} alt="" />
          <h3 >Revenue Cycle Management Service</h3>
        </div>
        <hr />
        <Link to="/whatweoffer/Revenue-Cycle-Management" onClick={() => window.scrollTo(0, 0)}><p className='wwd-sec2-1-cont' >AVANZA BPO offer our clients comprehensive Revenue Cycle Management Services by handling all responsibilities internally and taking complete accountability of overall collection for the physician’s groups, private practitioners, and/or Hospitals. Our End to End services includes all administrative and clinical functions that contribute to the capture, management, collection of patient service revenue including handling complex process 24/7 with specialized agents and proprietary technologies to manage healthcare provider revenue cycles. Our successful revenue cycle management relies on measuring key rates and statistics and setting regular goals to measure the efficacy of workflow changes and updates to existing protocols.</p>
        <p style={{color:'#243043', fontSize:'15px', textAlign:'end', marginRight:'3%', fontWeight:'600'}}>View more...</p></Link>

      </div> 
      
    </div>
    <hr style={{margin:'5% 0'}} />
    <div className='whatwedo-dis-sec3'>
      <div className='whatwedo-dis-sec3-1'>
        <div className='wwd-dis-heading'>
          <hr style={{width:'10%', height:'3px', backgroundColor:'#cf7531',marginTop:'5%'}}/>
          <h3 style={{fontWeight:'600',color:'#cf7531'}}>At Avanza BPO LLC</h3>
        </div>
        <h2 style={{color:'#083b4a',fontWeight:'600',width:'90%',marginTop:'2%'}}>Partner with Us for Excellence in Healthcare Management</h2>
        <p style={{textAlign:'justify'}}>At Avanza BPO LLC, we are committed to delivering solutions that not only meet but exceed your expectations. Partner with us to enhance your practice’s efficiency, compliance, and overall performance. Let’s work together to achieve excellence in healthcare practice management.</p>
      </div>
      <div className='whatwedo-dis-sec3-2'>
        <ContForm />
      </div>
    </div>

    </div>
  )
}

export default WhatWeDo