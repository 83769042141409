import React from 'react'
import { Link } from 'react-router-dom';
import './contarea.css'

function ContArea() {

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + '/brochure.pdf'; 
        link.download = 'Avanza_BPO_Brochure.pdf'; 
        link.click();
      };

  return (
    <div>
        <div id='download' className='downloadcontainer'>
        <h1 style={{textAlign:'center', color:'white'}}>Ready to Elevate Your Healthcare Business?</h1>
        <p style={{textAlign:'center', color:'white',margin:'0 10%'}}>Our team is here to help you take the next step towards operational excellence. Reach out to us today and discover how we can tailor our solutions to meet your needs. Don’t wait—unlock your healthcare practice's true potential with Avanza BPO!</p>
        <div style={{display:'flex', justifyContent:'center',marginTop:'2%'}}>
        <Link to='/contactus'><button onClick={() => window.scrollTo(0, 0)} className='downbtn'>Call to Action</button></Link> 
        </div>
      </div>
    </div>
  )
}

export default ContArea